export const filtersMixin = {
  filters: {
    roundDecimals(value) {
      if (isNaN(value)) {
        value = 0;
      }
      return Math.round(value);
    },
    roundTwoDecimals(value) {
      if (isNaN(value)) {
        value = 0;
      }
      return Math.round(value * 10) / 10;
    },
    currencyFormat(value) {
      var parts = value.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    commaSeparator(value) {
        var parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    //Introduced to divide net sum by thousand
    divideByThousand(value) {
      if (!value) {
        return 0;
      }
      const result = value / 1000;
      return value < 1000 ? result.toFixed(3) : result.toFixed(2);
    },
    dateRangeDescription(value) {
      var index = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2];
      var months = ["Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb"];
      return months[index.indexOf(parseInt(value))];
    },
    seasonRangeDescription(value) {
      var index = [111, 222];
      var seasons = ["Back To Hockey S'21 (Mar - Sep)", "Holiday S'21 (Oct - Feb)"];
      return seasons[index.indexOf(parseInt(value))];
    },
    isValidNumber(value) {
      if (isNaN(value) || !isFinite(value)) {
        return 0;
      } else {
        return value;
      }
    },
    ifUndefined(value) {
      if (value == null) {
        return "NA";
      } else {
        return value;
      }
    },
    calculateUnitTotals(arr) {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i].units;
      }
      return sum;
    },
    calculateGrossTotals(arr) {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i].gross;
      }
      return sum;
    },
    calculateAmountTotals(arr) {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i].amount;
      }
      return sum;
    },
    percent(value) {
      if (isNaN(value)) {
        return 0;
      }
      return `${Math.round(value * 100)}%`;
    },
    growthPercent(value) {
      if (isNaN(value) || !isFinite(value)) {
        return "0%";
      }
      return `${Math.round(value * 100) - 100}%`;
    },
  },
};
